<template>

    <el-menu class="el-menu-vertical-demo"
             mode="vertical"
             :unique-opened="true"
             :collapse-transition="false"
             :default-active="activeIndex"
             background-color="rgba(0,0,0,0)"
             text-color="#fff"
             style="border:none;"
             :collapse="isCollapse"
    >
        <template v-for="(item,index) in data">
            <template v-if="item.position!='top'">
                <template v-if="item.children&&item.children.length>0">
                    <VerMenuEleItem :menuObj="item"></VerMenuEleItem>
                </template>
                <template v-else>
                    <el-menu-item v-if="item.linkTo||item.href" :index="item.code" :key="item.code"
                                  @click.native="jump(item)" style="padding-left:20px;">
                        <i :class="`iconfont ${item.icon}`"></i>
                        <span slot="title">
                            {{ item.name }}
                        </span>
                    </el-menu-item>
                </template>
            </template>
        </template>
    </el-menu>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "VerMenuEle",
    components: {
        VerMenuEleItem: () => import("./VerMenuEleItem.vue"),
    },
    props: {
        isCollapse: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapState({
            menus: state => state.user.menus,
        })
    },
    data() {
        return {
            data:[],
            activeIndex: this.$route.name,
        }
    },
    watch: {
        $route:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (to){
                this.activeIndex = to.name;
                this.init()
            }
        },
        menus:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (){
                this.init()
            }
        },
    },
    created() {
    },
    methods: {
        init(){
            var code = this.$route.matched[0].name;
            this.menus.some(r=>{
                if(r.code==code){
                    this.data = r.children || [];
                }
            })
        },
        jump(item) {
            var path = item.linkTo ? item.linkTo : item.href;
            this.$router.push(path);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";

.el-menu-vertical-demo {
    //color: $themeColor;

    &:not(.el-menu--collapse) {
        width: 100%
    }

    * {
        &:hover {
            background: none !important;
        }
    }

    /deep/ .iconfont {
        vertical-align: middle;
        margin-right: 8px;
        width: 24px;
        text-align: center;
        font-size: 22px;
        display: inline-block;
        font-weight: lighter;
    }

    /deep/ .el-submenu__title {
        padding-left: 0 !important;
        padding-right: 40px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        margin-bottom: 1px;
        background: none !important;
        span {
            color: $defaultTextColor;
        }
        i {
            color: $defaultTextColor;
        }
    }

    /deep/ .el-menu-item-group__title {
        padding-top: 0;
        padding-bottom: 0;
        span {
            color: $defaultTextColor;
        }
    }

    /deep/ .el-menu-item {
        position:relative;
        padding: 0 10px;
        height: 42px;
        line-height: 42px;
        font-size: 16px;
        margin-bottom: 1px;
        color: $defaultTextColor;
        span {
            color: $defaultTextColor;
        }
        i {
            color: $defaultTextColor;
        }
        &:hover {
        }
        &.is-active {
            background: $themeShallowColor !important;
            &:after {
                content:"";
                position:absolute;
                top:0;
                right:-1px;
                bottom:0;
                width:4px;
                background:$themeColor;
            }
            i {
                color:$themeColor;
            }
            span {
                color:$themeColor;
            }
        }
    }
}
</style>
